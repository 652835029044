@import 'src/utilities/variables.scss';

.badge_list {
	li {
		display: inline-flex;
		margin: 5px;
		padding: 3px;
		background: #033e67;
		border: 1px solid #ccc9c9;
		box-sizing: border-box;
		border-radius: 4px;
	}

	&_item {
		display: flex;
		align-items: baseline;
		background: #033e67;
		border-radius: 2px;
		padding: 1px;
		position: relative;
	}

	&_label {
		color: white;
	}
}

.duplicate {
	background: $old-primary-color !important;
}
