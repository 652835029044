@import 'src/utilities/variables.scss';

.peg_wrapper {
	background-color: #f5f5f5;

	&_header {
		font-weight: 700;
		font-size: 18px;
		color: #033e67;
		padding: 20px;
		display: flex;
		justify-content: space-between;
	}

	&_accordion {
		padding: 0 20px 5px;
		min-height: 80vh;
		.peg_tabs {
			position: relative;
		}
		.ant-tabs-extra-content {
			position: absolute;
			top: 11px;
			left: 250px;
			padding: 2px 10px;
			background: $old-primary-color;
			border-radius: 50px;
			color: #ffffff;
			font-weight: bold;
		}
		& .ant-collapse {
			border: none;

			&-item {
				margin-bottom: 10px;
				background: #ffffff;
				box-shadow: 0px 4px 14px rgba(201, 201, 201, 0.35);
				border-radius: 4px;

				& .ant-collapse-header {
					font-weight: 400;
					font-size: 18px;
					color: #033e67 !important;
				}
			}

			&-item-active {
				& .ant-collapse-header {
					font-weight: 400;
					font-size: 18px;
					color: #000000 !important;
				}
			}
		}
	}

	.btnExport {
		margin-left: 15px;
		font-weight: 300;
		font-family: 'MuseoSans';
		border: 1px solid #4e6b8c;
		border-radius: 4px;
		height: 32px;
		line-height: 23px;
		padding: 4px 15px;
		display: inline-block;
	}
}

.downloadLogs {
	font-size: 14px;
	background: $button-color-primary;
	padding: 10px;
	color: white;
}

.pod_header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&_update {
		font-size: 15px;
		display: flex;
		align-items: center;
	}

	.btnRefresh {
		border-radius: 4px;
		margin-left: 5px;
	}
}
.ant-collapse > .ant-collapse-item.pod_panel {
	.ant-collapse-header .ant-collapse-arrow {
		top: 12px;
	}
}
