@import 'src/utilities/variables.scss';

.optionsModal {
	& .ant-modal-body {
		padding: 0;
	}

	& .ant-modal-footer {
		text-align: center;
		display: flex;
		flex-direction: column;
		padding: 20px ​16px;
		border-top: none;

		button {
			margin: 5px 40px !important;
		}
	}

	& .ant-modal-header {
		border-bottom: none;
	}

	& .ant-modal-title {
		justify-content: center;
		display: flex;
		font-size: 14px;
		font-weight: bold;
	}

	& .ant-modal-close-x {
		width: 40px;
		font-size: 14px;
		line-height: 37px;
	}
}

.colorModal {
	& .ant-modal-title {
		display: flex;
		font-size: 14px;
		font-weight: bold;
		text-transform: capitalize;
	}

	& .ant-modal-header {
		padding: 20px 24px 0px;
		border-bottom: none;
	}
}

.circle-picker span div span div {
	border: 1px solid #cccccc;

	&:focus {
		border: 2px solid $button-color-primary;
	}
}

.editor-container {
	background: white;
	border-radius: 3px;
	position: absolute;
	margin-top: 5px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
		rgba(0, 0, 0, 0.12) 0px 3px 1px -2px;

	.editor-input {
		border: none;
		padding-left: 5px;
	}

	.editor-btn-wrapper {
		background-color: rgb(240, 240, 240);
	}
}

.editor-btn {
	padding: 5px 50px;
	display: block;
}

.awssld {
	&__wrapper,
	&__content {
		overflow-y: auto;
		overflow-x: hidden;
	}

	&--last,
	&__next {
		visibility: visible;
	}
}

.left-outlined {
	position: absolute;
	z-index: 99;
	top: 50%;
	font-size: 40px;
	margin-left: 5px;
}

.right-outlined {
	position: absolute;
	z-index: 99;
	top: 50%;
	font-size: 40px;
}

.right-arrow-button {
	float: right;
	margin-right: 50px;
}

.left-arrow-button {
	float: left;
	margin-right: 50px;
}

.carouselWrapper {
	width: 100%;
	position: relative;

	.selectedText {
		position: sticky;
		top: 1px;
		left: 0;
		width: max-content;
		text-align: center;
		margin: 0 auto;
		z-index: 9;
		padding: 0 2px 0 14px;
		font-size: 15px;
		text-shadow: none;
		color: white;
		background: #033e67;
		border-radius: 20px;
		line-height: 20px;

		span {
			margin: 1px 5px 1px 15px;
			display: inline-block;
			border-left: 2px solid #0c314a;
			padding: 4px 8px;
			cursor: pointer;
		}
	}

	.imageContainer {
		position: relative;
	}
}

.c-wrapper {
	padding: 0 1em 10px 1em;
}

.carousel {
	& .control-dots {
		display: flex;
		justify-content: center;
		bottom: -37px !important;
		z-index: 0 !important;

		& .dot {
			background: #6a6a6a !important;
			width: 9px !important;
			height: 9px !important;
		}
	}

	& .carousel-slider {
		overflow: inherit !important;
		min-height: 60vh;
	}

	& .carousel-status {
		text-shadow: none !important;
		color: white !important;
		background: #033e67;
		border-radius: 0px 0px 0px 5px;
		top: 53px;
	}

	.right-arrow-button.carousel-btn {
		top: 0;
		position: absolute;
		right: 0;
	}

	.carousel-btn {
		margin: 10px 0px;
		min-width: 7em;
	}

	.c-s2 {
		top: 52px;
	}

	.thumbs-wrapper {
		margin: 20px 0;
		height: 47px;
		.thumbs {
			li {
				border: 1px solid #848484;
				width: 80px;
				height: 47px;
				margin-right: 0;
			}
		}
		.control-arrow {
			opacity: 1;
		}
		.control-prev.control-arrow:before {
			border-right-color: #033e67;
		}
		.control-next.control-arrow:before {
			border-left-color: #033e67;
		}
	}
}

.reviewFlag {
	position: absolute;
	top: 40px;
	right: 20px;
	height: 32px;
	padding: 4px 15px;
	font-size: 14px;
	border: 1px solid #4e6b8c;
	border-radius: 4px;
	color: #4e6b8c;
	z-index: 2;
}

.img-data {
	font-size: 12px;
	line-height: 1.3;
	border: 1px solid #cccccc;
	display: inline-block;
	text-align: left;
	padding: 25px 8px 0;
	width: 100%;
	background-color: #fef7e4;

	& .fieldInfo {
		display: inline-block;
		color: #79746a;

		strong {
			text-transform: capitalize;
			color: #242323;
		}

		&:not(:last-child) .fieldValue {
			border-right: 1px solid #cccccc;
		}

		& .fieldValue {
			margin-right: 10px;
			padding-right: 10px;
			margin-bottom: 8px;
		}
	}

	&:empty {
		background-color: transparent;
		border: none;
	}
}

.carousel.noFieldSelected {
	margin-top: 20px;

	& .carousel-status {
		top: -21px !important;
	}
}

.imgBlock {
	position: relative;
	.imgBoundingBox {
		position: absolute;
		z-index: 9;
		border-radius: 2px;
		background: #000000;
		border-color: #ffffff;
		border-width: 2px;
	}
}

.carouselContent {
	display: flex;
	align-items: center;
	flex-direction: column;
	// row-gap: 15px;
}

.slotWindow {
	position: absolute;
	top: 0;
	right: 0;
	padding: 5px;
	font-size: 10px;
	color: red;
}

@media screen and (min-width: 1800px) {
	.img-wrapper {
		max-height: 80vh !important;
	}
}

.updatedArrows {
	height: 100%;
	width: 1px;
	border: 1px solid #033e67;
	display: flex;
	justify-content: center;
	align-items: center;

	.alignSlider {
		position: absolute;
		top: 10%;
	}
}

.arrowBtn {
	display: flex;
	border-radius: 40px;
	overflow: hidden;
}

.arrow {
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.arrow:nth-child(1) {
	background-color: #033e67;
}

.arrow:nth-child(2) {
	background-color: red;
}

.delete-border {
	border: 5px solid red;
}

.delete-text {
	position: sticky;
	top: 1px;
	left: 1px;
	z-index: 99;

	& > span {
		color: white;
		background-color: red;
		padding: 7px 11px;
	}
}

.redaction-layout {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: row;

	&-sider,
	.ant-layout-sider {
		background-color: white;
	}

	&-border {
		border-right: 1px solid $border-color-light;
	}

	.ant-layout-sider-collapsed {
		min-width: 45px !important;
	}

	.ant-tabs-tab {
		color: #666666;
	}
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: $button-color-primary;
	}
	.ant-tabs-tab:hover {
		color: $button-color-primary;
	}
	.ant-tabs-ink-bar {
		background: $button-color-primary;
	}
}

.redaction-left-outlined {
	color: $button-color-primary;
	font-size: 28px;
	visibility: visible;
	position: absolute;
	right: 8px;
	top: 10px;

	&-icon {
		border: 1px solid $border-color-light;
		border-radius: 2px;
	}
}

.redaction-view {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 3px 12px;

	&-text {
		font-size: 1.65em;
		font-weight: 500;
	}

	&-wrapper {
		border-top: 1px solid $border-color-light;
		border-radius: 4px;
		width: 100%;
	}

	&-left {
		flex: 1;
		padding: 10px 15px;
		position: relative;
	}
}

.compareSlider {
	div {
		z-index: 9;

		.fst-img-wrapper {
			width: 100%;
			height: 100%;
			background: #fff;
		}
	}
}

.thumbs li img {
	display: block !important;
}
